import React from "react";
import { Link } from "react-router-dom";
// image
import logo from "../../images/logo/logo_full.png";

const Register = () => {
  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-12">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3">
                      <Link to="/">
                        <img src={logo} width="120" alt="" />
                      </Link>
                    </div>
                    <h4 className="text-center mb-4 ">Privacy Policy</h4>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
