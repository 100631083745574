import React, { useContext, useEffect, useState, Fragment } from "react";
// import { Nav } from "react-bootstrap";
import { Dropdown, Badge, Popover, OverlayTrigger, Button, Pagination, Alert, Modal } from "react-bootstrap";
import { ThemeContext } from "../../../context/ThemeContext";
import { Link } from "react-router-dom";
// import { Bar } from "react-chartjs-2";

// import pattern1 from "../../../images/pattern/pattern1.png";
// import pattern2 from "../../../images/pattern/pattern2.png";
import logoShort from "../../../images/logo/logo_dark_short.png";

import axios from 'axios';
import { baseURL, exchangeURL } from "../../../baseURL";
import Cookies from 'js-cookie';

import {SetBackgroundTheme} from "../../setBackgroundTheme";

const Dashboard = () => {
  const [wallets, setWallets] = useState([]);
  const [basicModal, setBasicModal] = useState(false);
  const [selectedWallet, setSelectedWallet] = useState({wallet_address: "", balance: 0});
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);
  const [transactions, setTransactions] = useState([]);

  const [selectedTransaction, setSelectedTransaction] = useState({from: "", to: "", amount: 0, fee: 0, amount_to_be_received: 0, message: 0});
  const [transactionModal, setTransactionModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [priceChange, setPriceChange] = useState(0);
  
  
  

  // const [totalProfit, setTotalProfit] = useState(0);
  // const [, setTotalVolume] = useState(0);
  // const [totalInvested, setTotalInvested] = useState(0);
  // const [netProfitPercent, setNetProfitPercent] = useState(0);
  // const [activeChart, setActiveChart] = useState('week');

  // const [chartDataValues, setChartDataValues] = useState([]);
  // const [chartDataPercentValues, setChartDataPercentValues] = useState([]);
  // const [chartLabels, setChartLabels] = useState([]);

  const [active, setActive] = useState(1);
  const [maxPage, setMaxPage] = useState(2);
  const [pageItems, setPageItems] = useState([{p:1}]);
  const [loadingBadge, setLoadingBadge] = useState(false);
  const [maxAvailablePage, setMaxAvailablePage] = useState(1);

  // const [priceSell, setPriceSell] = useState(0);
  // const [priceBuy, setPriceBuy] = useState(0);
  const [priceAvg, setPriceAvg] = useState(0);

  const { changeBackground } = useContext(ThemeContext);
  useEffect(() => {
    getCurrentPrice();
    getWallets();
    // getMainStatistic();
    // getChartData();
    // checkUserAuth();

    SetBackgroundTheme(changeBackground, Cookies);
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   // getChartData();
  //   // eslint-disable-next-line
  // }, [activeChart]);

  function logOutWallet() {
    const wallets_tmp = wallets;
    const wallets_new = [];
    for(const w of wallets_tmp) {
      if(selectedWallet.wallet_address !== w.wallet_address) {
        wallets_new.push(w);
      }
    }
    setWallets([...wallets_new]);
    Cookies.set('wallets', JSON.stringify(wallets_new), { expires: 30, path: '/'});
    setBasicModal(false);
  }

  async function getCurrentPrice() {
    const data = {}
    const response = await axios.post(exchangeURL+'/api/orders/get_current_price', data)
    if(response.data.status === 'ok') {
      // setPriceSell(response.data.price_sell);
      // setPriceBuy(response.data.price_buy);

      const avg_price = (parseFloat(response.data.price_sell) + parseFloat(response.data.price_buy)) / 2;
      setPriceAvg(avg_price);
      setPriceChange(response.data.price_change_1m);
    }
  }

  function convertFormatNumber(number) {
    const europeanFormat = new Intl.NumberFormat('en-US', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(number);

    return europeanFormat;
  }

  async function getWallets() {
    setLoading(true);
    const wallets_ = Cookies.get('wallets');
    if(wallets_ === undefined || wallets_ === "undefined" || wallets_ === "[]") {
      window.location.href = '/page-login';
    } else {
      var wallets_all = JSON.parse(wallets_);
      for(const w of wallets_all) {
        // if(w['balance'] === undefined) {
        //   try{ 
        //     w['balance'] = await getWalletBalance(w['wallet_address']);
        //   } catch(e) {
        //     w['balance'] = 0;
        //   }
        // }
        w['balance'] = await getWalletBalance(w['wallet_address']);
        setSelectedWallet(w);
      }
      setWallets(wallets_all);

      if(wallets_all.length > 0) {
        console.log(1, wallets_all[wallets_all.length - 1]['wallet_address'])
        await getWalletTransactions(1, wallets_all[wallets_all.length - 1]['wallet_address'])
      }
      
    }
  }

  async function getWalletBalance(wallet_address) {
    var balance = 0;
    const data = { wallet_address: wallet_address }
    const response = await axios.post(baseURL+'/get_wallet_balance', data)
    if(response.data.status === 'ok') {
      balance = response.data.balance;
    }
    return balance;
  }

  async function copyToClipboard(text) {
    try {
      await navigator.clipboard.writeText(text);
      setCopiedToClipboard(true);
      await delay(3000);
      setCopiedToClipboard(false);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  async function getWalletTransactions(page_number, wallet_address) {
    setLoadingBadge(true);
    const data = {
      wallet_address: wallet_address,
      page: page_number
    }

    try {
      const response = await axios.post(baseURL+'/get_wallet_transactions', data);
      console.log("getWalletTransactions: ", response.data)
      if(response.data.status === 'ok') {
        setTransactions(response.data.transactions);
        setNavigationLinks(response.data.max_page);
        setMaxAvailablePage(response.data.max_page);
        setMaxPage(response.data.max_page);
        changePageNoReload(page_number, response.data.max_page);
      } else {
        // setError(response.data.text);
      }
    } catch(err) {
      console.log(err)
    }
    setLoadingBadge(false);
    setLoading(false);
    // setLoadingClosedPositions(false);
  }



  function setNavigationLinks(max_page) {
    // var page_items_tmp = [];
    // for (let number = active; number <= max_page; number++) {
    //   page_items_tmp.push({p:number});
    //   if(page_items_tmp.length >= 10) {
    //     break;
    //   }
    //   if(parseInt(number) <= parseInt(max_page)) {
    //     break;
    //   }
    // }
    // setPageItems(page_items_tmp);
  }

  function changePage(page) {
    setLoadingBadge(true);
    var page_number = parseInt(page);
    setActive(page_number);

    var number_first = 1;
    var max_page = maxPage;
    if(page_number > 5) {
      number_first = page_number - 5;
      max_page = max_page + number_first;
      if(max_page > maxAvailablePage){
        max_page = maxAvailablePage;
      }
    }

    var page_items_tmp = [];
    for (let number = number_first; number <= max_page; number++) {
      console.log("max_page: ", max_page);
      console.log("number: ", number);
      
      if(page_items_tmp.length >= 10) {
        break;
      }
      page_items_tmp.push({p:number});
    }
    setPageItems(page_items_tmp);
    getWalletTransactions(page_number, selectedWallet.wallet_address);
  }

  function changePageNoReload(page, max_page) {
    setLoadingBadge(true);
    var page_number = parseInt(page);
    setActive(page_number);

    var number_first = 1;
    if(page_number > 5) {
        number_first = page_number - 5;
        max_page = max_page + number_first;
    }

    var page_items_tmp = [];
    for (let number = number_first; number <= max_page; number++) {
      page_items_tmp.push({p:number});
      if(page_items_tmp.length >= 10) {
        break;
      }
      // if(parseInt(number) <= parseInt(max_page)) {
      //   break;
      // }
    }
    setPageItems(page_items_tmp);
    setLoadingBadge(false);
  }

  function changePagePrev() {
    var next_page = active - 1;
    if(next_page < 1) {
      next_page = 1;
    }
    setActive(next_page);
    changePage(next_page);
  }

  function changePageNext() {
    const next_page = active + 1;
    if(next_page <= maxAvailablePage) {
      setActive(next_page);
      changePage(next_page);
    }
  }

  function convertTimestampToYmdHis(timestamp) {
    // Create a new Date object from the timestamp
    const date = new Date(timestamp * 1000);

    // Extract the date components
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);

    // Construct the formatted date string
    const formattedDate = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
    return formattedDate;
  }


  // let path = window.location.pathname;
  // path = path.split("/");
  // var location = path[path.length - 1];
  // location = location.split("?")[0];

  function openLoginPage() {
    window.location.href = "/page-login";
  }

  return (
    <Fragment>
      {/* <SetBackgroundTheme /> */}

      <div className=" text-left mb-4">
        <Link to="#" onClick={() => openLoginPage()} className="btn btn-primary btn-rounded">
          + Add Wallet
        </Link>
      </div>

      {loading === true && (
        <div className="">
          <Badge variant="info light">Loading...</Badge>
        </div>
      )}

      {loading === false && (
        <>
          <div className="row">
            {wallets.map((item, key) => (
              <div key={`00000-${key}`} className="col-12 col-md-6">
                <div className="card border coin-holding-card border-info">
                  <div className="card-body bg-info rounded">
                    <div className="d-flex align-items-center">
                      {/* <svg className="mr-3" width={42} height={42} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.9999 0.00012207C9.40201 0.00012207 0 9.40213 0 21C0 32.5979 9.40201 41.9999 20.9999 41.9999C32.5978 41.9999 41.9998 32.5979 41.9998 21C41.987 9.40762 32.5923 0.0129395 20.9999 0.00012207ZM12.328 19.4999H18.3279C19.1565 19.4999 19.828 20.1715 19.828 21C19.828 21.8286 19.1565 22.5001 18.3279 22.5001H12.328C11.4995 22.5001 10.8279 21.8286 10.8279 21C10.8279 20.1715 11.4999 19.4999 12.328 19.4999ZM31.084 17.3658L29.2799 26.392C28.8551 28.4872 27.0154 29.9951 24.8776 30.0001H12.328C11.4995 30.0001 10.8279 29.3286 10.8279 28.5C10.8279 27.6715 11.4999 26.9999 12.328 26.9999H24.8776C25.5867 26.9981 26.1969 26.4982 26.3379 25.8033L28.1424 16.7772C28.3026 15.9715 27.7798 15.1887 26.9746 15.0285C26.879 15.0097 26.7819 15.0001 26.6849 15.0001H15.3282C14.4997 15.0001 13.8281 14.3286 13.8281 13.5C13.8281 12.6715 14.4997 11.9999 15.3282 11.9999H26.6849C29.1632 12.0009 31.1714 14.01 31.171 16.4883C31.171 16.7827 31.1417 17.0765 31.084 17.3658Z" fill="white" />
                      </svg> */}
                      <img alt="" src={logoShort} width="40" height={40} className="mr-3" />
                      <div>
                        <h4 className="font-w500 text-white title mb-0">
                          DEFCUR
                        </h4>
                        <span className="text-white fs-14 op7">Deflationary Digital Currency</span>
                      </div>
                    </div>
                    <div className="d-flex flex-wrap mt-4 align-items-center">
                      <div className="d-flex align-items-center mr-auto pr-3 mb-2">
                        <svg className="mr-3" width={42} height={26} viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect width="3.42856" height="25.1428" rx="1.71428" transform="matrix(-1 0 0 1 24 0)" fill="white" />
                          <rect width="3.42856" height="18.2856" rx="1.71428" transform="matrix(-1 0 0 1 17.1431 6.85712)" fill="white" />
                          <rect width="3.42856" height="7.99997" rx="1.71428" transform="matrix(-1 0 0 1 10.2861 17.1428)" fill="white" />
                          <rect width="3.86812" height="15.4725" rx="1.93406" transform="matrix(-1 0 0 1 3.86816 9.67029)" fill="white" />
                        </svg>
                        <h4 className="font-w500 text-white amount mb-0">
                          {item.balance}
                        </h4>
                      </div>
                      <div className="mb-2">
                        <svg width={14} height={8} viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M0.707108 6.06712C0.0771426 6.69709 0.523309 7.77423 1.41421 7.77423L12.3601 7.77423C13.251 7.77423 13.6972 6.69709 13.0672 6.06712L7.59426 0.594186C7.20373 0.203662 6.57057 0.203662 6.18005 0.594186L0.707108 6.06712Z" fill="#61C277" />
                        </svg>
                        <span className="text-white fs-14 px-1">{priceChange.toFixed(2)}% This Month</span>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer d-flex justify-content-between border-0">
                    <div className="footer-info font-w600">
                      <span className="px-1 ">Cost in USD</span>
                      <span className="text-black px-1 d-block ">~${convertFormatNumber(priceAvg * item.balance)}</span>
                    </div>
                    {/* <div className="footer-info font-w600">
                      <span className="text-danger px-1 ">SELL</span>
                      <span className="text-black px-1 d-block">$5,982</span>
                    </div> */}
                    <button className="btn btn-sm underline text-primary font-w500 footer-info" onClick={() => {setBasicModal(true); setSelectedWallet(item)}}>
                      More Details
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="row mb-5">
            <div className="col-xl-12">
              <div className="card table-responsive table-hover fs-14 ">

                <div className="card-header d-sm-flex d-block pb-0 border-0">
                  <div>
                    <h4 className="fs-20 text-black">Transactions</h4>
                    <p className="mb-0 fs-12">
                      Transactions History
                    </p>
                  </div>
                  
                  <Dropdown className="dropdown bootstrap-select form-control style-1 default-select mb-3">
                    <Dropdown.Toggle
                      variant=""
                      type="button"
                      className="btn dropdown-toggle btn-light"
                      data-toggle="dropdown"
                      role="button"
                      title="Latest"
                      aria-expanded="false"
                    >
                      Balance: {selectedWallet.balance}. Wallet: {selectedWallet.wallet_address.substring(0, 5)}......{selectedWallet.wallet_address.substring(selectedWallet.wallet_address.length - 5)}
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className="dropdown-menu"
                      role="combobox"
                      x-placement="bottom-start"
                      style={{
                        maxHeight: 174,
                        overflow: "hidden",
                        minHeight: 0,
                        position: "absolute",
                        willChange: "transform",
                        top: 0,
                        left: 0,
                        transform: "translate3d(0px, 41px, 0px)",
                      }}
                    >
                      {wallets.length > 0 &&
                        wallets.map((item, key) => (
                          <Dropdown.Item key={`10000-${key}`} onClick={() => {setSelectedWallet(item); getWalletTransactions(1, item.wallet_address)}}>
                            Balance: {item.balance}. Wallet: {item.wallet_address.substring(0, 20)}......{item.wallet_address.substring(item.wallet_address.length - 20)}
                          </Dropdown.Item>
                        )
                      )}
                    </Dropdown.Menu>
                  </Dropdown>

                </div>

                <div id="example6_wrapper" className="dataTables_wrapper no-footer">

                  {transactions.length === 0 && (
                    <div className="col-12">
                      <Alert variant="primary" className="alert-dismissible fade show">
                        There are no transactions at this moment.
                      </Alert>
                    </div>
                  )}

                  {transactions.length > 0 && (
                    <table
                      className="table display dataTablesCard font-w600 short-one border-no card-table text-black dataTable no-footer"
                      id="marketCapital"
                      role="grid"
                      aria-describedby="example6_info"
                    >
                      <thead>
                        <tr role="row">
                          <th className="sorting_asc">Date</th>
                          <th className="sorting_asc">From</th>
                          <th className="sorting_asc">To</th>
                          <th className="sorting_asc">Transaction Type</th>
                          <th className="sorting_asc">Amount</th>
                          <th className="sorting_asc">Fee</th>
                          <th className="sorting_asc">Amount to be received</th>
                          <th className="sorting_asc">More</th>
                        </tr>
                      </thead>
                      <tbody>
                        {transactions.length > 0 &&
                          transactions.map((item, key) => (
                            <tr key={`20000-${key}`} role="row" className="odd">
                              <td className="wspace-no sorting_1">
                                {convertTimestampToYmdHis(item.message)}
                              </td>
                              <td className="wspace-no sorting_1">
                                {item.from === 'network' && (
                                  <Button variant="info light" size="xs" className="">
                                    <span>{item.from}</span>
                                  </Button>
                                )}
                                {item.from !== 'network' && (
                                  // <span>{item.from.substring(0, 10) + "............" + item.from.substring(item.from.length - 10)}</span>
                                  <OverlayTrigger
                                    trigger="click"
                                    placement="top"
                                    responsive={true}
                                    className="primary"
                                    overlay={
                                      <Popover
                                        id={`popover-positioned-top`}
                                      >
                                        <Popover.Content>
                                          {item.from}
                                        </Popover.Content>
                                      </Popover>
                                    }
                                  >
                                    <Button variant="info light" size="xs" className="">
                                      <span>{item.from.substring(0, 10) + "............" + item.from.substring(item.from.length - 10)}</span>
                                    </Button>
                                  </OverlayTrigger>
                                )}
                              </td>
                              <td className="wspace-no sorting_1">
                                {item.to === 'network' && (
                                  <Button variant="info light" size="xs" className="">
                                    <span>{item.to}</span>
                                  </Button>
                                )}
                                {item.to !== 'network' && (
                                  // <span>{item.to.substring(0, 10) + "............" + item.to.substring(item.to.length - 10)}</span>
                                  <OverlayTrigger
                                    trigger="click"
                                    placement="top"
                                    responsive={true}
                                    className="primary"
                                    overlay={
                                      <Popover
                                        id={`popover-positioned-top`}
                                      >
                                        <Popover.Content>
                                          {item.to}
                                        </Popover.Content>
                                      </Popover>
                                    }
                                  >
                                    <Button variant="info light" size="xs" className="">
                                      <span>{item.to.substring(0, 10) + "............" + item.to.substring(item.to.length - 10)}</span>
                                    </Button>
                                  </OverlayTrigger>
                                )}
                              </td>
                              <td className="wspace-no sorting_1">
                                {item.to === selectedWallet.wallet_address && (
                                  <Badge variant="success light">Deposit</Badge>
                                )}
                                {item.from === selectedWallet.wallet_address && (
                                  <Badge variant="info light">Withdrawal</Badge>
                                )}
                              </td>
                              <td className="wspace-no sorting_1">
                                {item.amount} DEFCUR
                              </td>
                              <td className="wspace-no sorting_1">
                                {item.fee} DEFCUR
                              </td>
                              <td className="wspace-no sorting_1">
                                {item.amount_to_be_received} DEFCUR
                              </td>
                              <td className="wspace-no sorting_1">
                                <button className="btn btn-xs btn-info" onClick={() => {setSelectedTransaction(item); setTransactionModal(true);}}>Details</button>
                              </td>
                            </tr>
                          )
                        )}
                        {/* <tr role="row" className="odd">
                          <td className="wspace-no sorting_1">
                            {convertTimestampToYmdHis(1719427737)}
                          </td>
                          <td className="wspace-no sorting_1">
                            {"wlRrBWXA3EDd7Ps6zYNObkVnlO/tBIgDNsaqIr016JNQlh48HAppMF9a/lHFaq0AmbEM8ysLgwjSDlyBftC+vA==".substring(0, 10) + "............" + "wlRrBWXA3EDd7Ps6zYNObkVnlO/tBIgDNsaqIr016JNQlh48HAppMF9a/lHFaq0AmbEM8ysLgwjSDlyBftC+vA==".substring("wlRrBWXA3EDd7Ps6zYNObkVnlO/tBIgDNsaqIr016JNQlh48HAppMF9a/lHFaq0AmbEM8ysLgwjSDlyBftC+vA==".length - 10)}
                          </td>
                          <td className="wspace-no sorting_1">
                            <Badge variant="success light">Topup</Badge>
                          </td>
                          <td className="wspace-no sorting_1">
                            10.1 DEFCUR
                          </td>
                          <td className="wspace-no sorting_1">
                            <button className="btn btn-xs btn-info">Details</button>
                          </td>
                        </tr>

                        <tr role="row" className="odd">
                          <td className="wspace-no sorting_1">
                            {convertTimestampToYmdHis(1719427737)}
                          </td>
                          <td className="wspace-no sorting_1">
                            {"wlRrBWXA3EDd7Ps6zYNObkVnlO/tBIgDNsaqIr016JNQlh48HAppMF9a/lHFaq0AmbEM8ysLgwjSDlyBftC+vA==".substring(0, 10) + "............" + "wlRrBWXA3EDd7Ps6zYNObkVnlO/tBIgDNsaqIr016JNQlh48HAppMF9a/lHFaq0AmbEM8ysLgwjSDlyBftC+vA==".substring("wlRrBWXA3EDd7Ps6zYNObkVnlO/tBIgDNsaqIr016JNQlh48HAppMF9a/lHFaq0AmbEM8ysLgwjSDlyBftC+vA==".length - 10)}
                          </td>
                          <td className="wspace-no sorting_1">
                            <Badge variant="info light">Withdrawal</Badge>
                          </td>
                          <td className="wspace-no sorting_1">
                            -10.1 DEFCUR
                          </td>
                          <td className="wspace-no sorting_1">
                            <button className="btn btn-xs btn-info">Details</button>
                          </td>
                        </tr> */}
                      </tbody>
                    </table>
                  )}
                </div>

                <div className="pl-4 pb-4">
                  <Pagination key={'pagination001'}
                    size="sm"
                    className={`pagination-gutter pagination-primary no-bg pagination-circle`}
                  >
                    <li key={'3100abc'} className="page-item page-indicator">
                      <Link key={'3101abc'} className="page-link" to="#" onClick={() => changePagePrev()}>
                        <i className="la la-angle-left" />
                      </Link>
                    </li>

                    {pageItems.length > 0 &&
                      pageItems.map((item, key) => (
                        <div key={`30000-${item.p}`}>
                          {item.p <= maxPage && (
                            <Pagination.Item active={item.p === active ? true : false} onClick={() => {changePage(item.p)}}>
                              {item.p}
                            </Pagination.Item>
                          )}
                        </div>
                      )
                    )}
                    
                    <li key={'3200abc'} className="page-item page-indicator">
                      <Link key={'3201abc'} className="page-link" to="#" onClick={() => changePageNext()}>
                        <i className="la la-angle-right" />
                      </Link>
                    </li>
                  </Pagination>
                  
                </div>

                {loadingBadge === true && (
                  <div className="pl-4 pb-4">
                    <Badge variant="info light">Loading...</Badge>
                  </div>
                )}

              </div>

              <Modal className="fade" show={transactionModal}>
                <Modal.Header>
                  <Modal.Title>Transaction Details</Modal.Title>
                  <Button
                    variant=""
                    className="close"
                    onClick={() => {setTransactionModal(false)}}
                  >
                    <span>&times;</span>
                  </Button>
                </Modal.Header>
                <Modal.Body>

                  
                  <span className="text-break"><b>From: </b> {selectedTransaction.from}</span><br/><br/>
                  <span className="text-break"><b>To: </b> {selectedTransaction.to}</span><br/><br/>
                  <span className="text-break"><b>Timestamp: </b> {selectedTransaction.message}</span><br/>
                  <b>Amount: </b> {selectedTransaction.amount}<br/>
                  <b>Fee: </b> {selectedTransaction.fee}<br/>
                  <b>Amount to be received: </b> {selectedTransaction.amount_to_be_received}<br/><hr/>

                  <Button
                    onClick={() => {setTransactionModal(false)}}
                    variant="info light"
                    className="btn-block"
                  >
                    Close
                  </Button>

                </Modal.Body>
              </Modal>

            </div>
          </div>
        </>
      )}

      <Modal className="fade" show={basicModal}>
        <Modal.Header>
          <Modal.Title>Wallet Details</Modal.Title>
          <Button
            variant=""
            className="close"
            onClick={() => setBasicModal(false)}
          >
            <span>&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <p className="text-break"><b>Address: </b> {selectedWallet.wallet_address}
          <br/>
          {copiedToClipboard === false && (
            <small onClick={() => copyToClipboard(selectedWallet.wallet_address)} style={{cursor: 'pointer', textDecoration: 'underline'}} className="text-info">(Click to copy the wallet address)</small>
          )}
          {copiedToClipboard === true && (
            <small className="text-success">The wallet address has been copied to the clipboard.</small>
          )}
          <br/>
          </p>
          <b>Balance: </b> {selectedWallet.balance}<br/>

          <Button
            onClick={() => setBasicModal(false)}
            variant="info light"
            className="mt-4 btn-block"
          >
            Close
          </Button>

          <Button
            onClick={() => logOutWallet()}
            variant="warning light"
            className="mt-3 btn-block"
          >
            Log Out
          </Button>
        </Modal.Body>
      </Modal>

    </Fragment>
  );
};

export default Dashboard;
