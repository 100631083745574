import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import { Badge, Dropdown, Button, Alert, Modal, Pagination } from "react-bootstrap";
import {SetBackgroundTheme} from "../../setBackgroundTheme";
import loadingGif from "../../../images/loading.gif";
import DaiImage from "../../../images/crypto/DAI.png";
import TradingViewChart from './TradingViewChart';
import axios from 'axios';
import { baseURL, exchangeURL } from "../../../baseURL";
import Cookies from 'js-cookie';
// import loadable from "@loadable/component";
// import pMinDelay from "p-min-delay";

const BuySell = ({ history, setAccountConnected }) => {

  const buyPoints = []; // { time: '2024-07-20', price: 150 },
  const sellPoints = []; // { time: '2024-07-22', price: 160 },

  async function changeTimeFrame(time_frame) {
    setTimeFrame(time_frame);
    await getChartData(time_frame);
  }


  
  const { background } = useContext(ThemeContext);

  const [chartData, setChartData] = useState([]);
  const [timeFrame, setTimeFrame] = useState('1d');
  const [changingTimeFrame, setChangingTimeFrame] = useState(false);
  

  const [wallets, setWallets] = useState([]);
  const [selectedWallet, setSelectedWallet] = useState({wallet_address: "", balance: 0});
  const [passcode, setPasscode] = useState('');

  const [sendingDaiWalletAddress, setSendingDaiWalletAddress] = useState('');
  const [networkCommissionDai, setNetworkCommissionDai] = useState(1);

  const [userDaiWalletAddress, setUserDaiWalletAddress] = useState('');
  const [disabledUpdateBuyOrderButton, setDisabledUpdateBuyOrderButton] = useState(false);
  
  const [sellModal, setSellModal] = useState(false);
  const [orderDetailsModal, setOrderDetailsModal] = useState(false);
  

  const [sellWalletConfirmed, setSellWalletConfirmed] = useState(false);
  const [buyWalletConfirmed, setBuyWalletConfirmed] = useState(false);

  const [disabledSellNextButton, setDisabledSellNextButton] = useState(false);
  const [disabledBuyNextButton, setDisabledBuyNextButton] = useState(false);
  const [disabledUpdateTransactionButton, setDisabledUpdateTransactionButton] = useState(false);

  const [verifySellError, setVerifySellError] = useState('');
  const [verifyBuyError, setVerifyBuyError] = useState('');
  const [buyOrderID, setBuyOrderID] = useState('');
  
  
  
  const [amountSell, setAmountSell] = useState(0);

  const [priceSell, setPriceSell] = useState(0);
  const [priceBuy, setPriceBuy] = useState(0);
  const [daiWalletAddress, setDaiWalletAddress] = useState('0x604832Ac4Bc822F42CFD2F13DaF1064575F5Ab54');
  const [DaiNetwork, setDaiNetwork] = useState('eth');

  const [notEnoughBalance, setNotEnoughBalance] = useState(false);
  const [sellOrderSent, setSellOrderSent] = useState(false);
  const [sellOrderError, setSellOrderError] = useState('');
  
  const [disabledSellConfirmButton, setDisabledSellConfirmButton] = useState(false);

  const [buyModal, setBuyModal] = useState(false);
  const [amountBuy, setAmountBuy] = useState(0);
  // const [targetDaiWalletAddress, setTargetDaiWalletAddress] = useState('');
  // const [targetDaiNetwork, setTargetDaiNetwork] = useState('eth');
  const targetDaiNetwork = 'eth';
  const [buyOrderSent, setBuyOrderSent] = useState(false);
  const [buyOrderError, setBuyOrderError] = useState('');
  const [disabledBuyConfirmButton, setDisabledBuyConfirmButton] = useState(false);
  
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);

  const [active, setActive] = useState(1);
  const [maxPage, setMaxPage] = useState(2);
  const [pageItems, setPageItems] = useState([{p:1}]);
  const [loadingBadge, setLoadingBadge] = useState(false);
  const [maxAvailablePage, setMaxAvailablePage] = useState(1);
  const [orderList, serOrderList] = useState([]);
  const [selectedTransaction, setSelectedTransaction] = useState({id: "", side: "", defcur_wallet: "", amount: "", price: "", usd: "", coin: "", wallet_address: "", wallet_network: "", transaction_id: "", hash: "", status: "", timestamp_created: "", timestamp: ""});

  const [maxBuyCoins, setMaxBuyCoins] = useState('...');
  const [disabledBuyButton, setDisabledBuyButton] = useState(false);

  const [loadingWallets, setLoadingWallets] = useState(true);
  const [loadingChartData, setLoadingChartData] = useState(true);
  const [loadingCurrentPrice, setLoadingCurrentPrice] = useState(true);
  const [loadingRestrictions, setLoadingRestrictions] = useState(true);
  
  
  

  const { changeBackground } = useContext(ThemeContext);
  useEffect(() => {
    getWallets();
    getRestrictions();
    SetBackgroundTheme(changeBackground, Cookies);
  }, []);

  async function getWallets() {
    setLoadingWallets(true);
    const wallets_ = Cookies.get('wallets');
    if(wallets_ === undefined || wallets_ === "undefined") {
      window.location.href = '/page-login';
    } else {
      var wallets_all = JSON.parse(wallets_);
      for(const w of wallets_all) {
        if(w['balance'] === undefined) {
          try{ 
            w['balance'] = await getWalletBalance(w['wallet_address']);
          } catch(e) {
            w['balance'] = 0;
          }
        }
        setSelectedWallet(w);
      }
      setWallets(wallets_all);
      // if(wallets_all.length > 0) {
      //   getOrdersList(1, wallets_all[wallets_all.length -1].wallet_address);
      // }
      getOrdersList(1, wallets_all[wallets_all.length -1].wallet_address);
      getChartData(timeFrame);
      getCurrentPrice();
      
    }
  }

  async function getCurrentPrice() {
    const data = {}
    try {
      const response = await axios.post(exchangeURL+'/api/orders/get_current_price', data)
      if(response.data.status === 'ok') {
        setPriceSell(response.data.price_sell);
        setPriceBuy(response.data.price_buy);
      }
    } catch(e) {
      await delay(2000);
      await getCurrentPrice();
    }
    setLoadingCurrentPrice(false);
  }

  async function getRestrictions() {
    const data = {}
    try {
      const response = await axios.post(exchangeURL+'/api/orders/get_restrictions', data)
      if(response.data.status === 'ok') {
        setMaxBuyCoins(response.data.defcur_buy);
      }
    } catch(e) {
      await delay(2000);
      await getRestrictions();
    }
    setLoadingRestrictions(false);
  }

  function checkLimitBuy(value) {
    const available_coins = maxBuyCoins - parseFloat(value);
    if(available_coins >= 0) {
      setDisabledBuyButton(false);
    } else {
      setDisabledBuyButton(true);
    }
  }

  async function getChartData(time_frame) {
    setChangingTimeFrame(true);
    const data = {time_frame: time_frame}
    try {
      const response = await axios.post(exchangeURL+'/api/orders/get_chart', data)
      if(response.data.status === 'ok') {
        console.log(response.data.chartData);
        setChartData([...response.data.chartData]);
      }
      setChangingTimeFrame(false);
    } catch(e) {
      await delay(2000);
      await getChartData(time_frame);
    }
    setLoadingChartData(false);
  }

  async function getOrdersList(page_number, wallet_address) {
    const data = { wallet_address: wallet_address, page: page_number }
    try {
      const response = await axios.post(exchangeURL+'/api/orders/get_list', data)
      if(response.data.status === 'ok') {
        serOrderList([...response.data.orders]);
        setNavigationLinks(response.data.max_page);
        setMaxAvailablePage(response.data.max_page);
        setMaxPage(response.data.max_page);
        changePageNoReload(page_number, response.data.max_page);
      }
    } catch(e) {
      await delay(2000);
      await getOrdersList(page_number, wallet_address)
    }
    setLoadingWallets(false);
  }

  function setNavigationLinks(max_page) {
    var page_items_tmp = [];
    for (let number = active; number <= max_page; number++) {
        page_items_tmp.push({p:number});
    }
    setPageItems(page_items_tmp);
  }

  function changePage(page) {
    setLoadingBadge(true);
    var page_number = parseInt(page);
    setActive(page_number);

    var number_first = 1;
    var max_page = maxPage;
    if(page_number > 5) {
      number_first = page_number - 5;
      max_page = max_page + number_first;
      if(max_page > maxAvailablePage){
        max_page = maxAvailablePage;
      }
    }

    var page_items_tmp = [];
    for (let number = number_first; number <= max_page; number++) {
        page_items_tmp.push({p:number});
    }
    setPageItems(page_items_tmp);
    getOrdersList(page_number, selectedWallet.wallet_address);
    setLoadingBadge(false);
  }

  function changePageNoReload(page, max_page) {
    setLoadingBadge(true);
    var page_number = parseInt(page);
    setActive(page_number);

    var number_first = 1;
    if(page_number > 5) {
        number_first = page_number - 5;
        max_page = max_page + number_first;
    }

    var page_items_tmp = [];
    for (let number = number_first; number <= max_page; number++) {
        page_items_tmp.push({p:number});
    }
    setPageItems(page_items_tmp);
    setLoadingBadge(false);
  }

  function changePagePrev() {
    var next_page = active - 1;
    if(next_page < 1) {
      next_page = 1;
    }
    setActive(next_page);
    changePage(next_page);
  }

  function changePageNext() {
    const next_page = active + 1;
    if(next_page <= maxAvailablePage) {
      setActive(next_page);
      changePage(next_page);
    }
  }

  async function getWalletBalance(wallet_address) {
    var balance = 0;
    const data = { wallet_address: wallet_address }
    try{
      const response = await axios.post(baseURL+'/get_wallet_balance', data)
      if(response.data.status === 'ok') {
        balance = response.data.balance;
      }
      return balance;
    } catch(e) {
      await delay(2000);
      await getWalletBalance(wallet_address);
    }
  }

  async function verifyWalletPasscode(side) {
    setDisabledSellNextButton(true);
    setDisabledBuyNextButton(true);
    const data = {
      wallet: selectedWallet.wallet,
      passcode: passcode
    }

    
    try{
      const response = await axios.post(exchangeURL+'/api/orders/get_gas_price_dai_eth', data)
      console.log("sendSellOrder: ", response.data);
      if(response.data.status === 'ok') {
        setNetworkCommissionDai(response.data.totalGasCostUsd);
      }
    } catch(e) {
      await delay(2000);
      await verifyWalletPasscode(side);
    }

    try{
      const response = await axios.post(exchangeURL+'/api/orders/confirm_wallet', data)
      console.log("sendSellOrder: ", response.data);
      if(response.data.status === 'ok') {
        if(side === 'sell') {
          setSellWalletConfirmed(true);
        }
        if(side === 'buy') {
          setBuyWalletConfirmed(true);
        }
      } else {
        if(side === 'sell') {
          setVerifySellError(response.data.text);
        }
        if(side === 'buy') {
          setVerifyBuyError(response.data.text);
        }
      }
    } catch(e) {
      console.log(e);
      await delay(2000);
      await verifyWalletPasscode(side);
    }
    setDisabledSellNextButton(false);
    setDisabledBuyNextButton(false);
  }

  async function sendSellOrder() {
    setDisabledSellConfirmButton(true);

    const balance_new = parseFloat(selectedWallet.balance) - parseFloat(amountSell);
    if(balance_new < 0) {
      setNotEnoughBalance(true);
      setDisabledSellConfirmButton(false);
      return ""
    }
    setNotEnoughBalance(false);

    const data = {
      wallet: selectedWallet.wallet,
      passcode: passcode,
      from: selectedWallet.wallet_address,
      amount: amountSell,
      price: priceSell,
      wallet_address: userDaiWalletAddress,
      wallet_network: DaiNetwork
    }

    try{
      const response = await axios.post(exchangeURL+'/api/orders/create_sell', data)
      console.log("sendSellOrder: ", response.data);
      if(response.data.status === 'ok') {
        getOrdersList(active, selectedWallet.wallet_address);
        setSellOrderSent(true);
      } else {
        setSellOrderSent(false);
        setSellOrderError(response.data.text);
      }
    } catch(e) {
      console.log(e);
    }
    setDisabledSellConfirmButton(false);
  }

  async function sendBuyOrder() {
    setDisabledBuyConfirmButton(true);

    const data = {
      to: selectedWallet.wallet_address,
      wallet: selectedWallet.wallet,
      passcode: passcode,
      amount: amountBuy,
      price: priceBuy,
      wallet_network: targetDaiNetwork
    }

    try{
      const response = await axios.post(exchangeURL+'/api/orders/create_buy', data)
      console.log("sendSellOrder: ", response.data);
      if(response.data.status === 'ok') {
        getOrdersList(active, selectedWallet.wallet_address);
        setBuyOrderSent(true);
        setBuyOrderID(response.data.order_id);
      } else {
        setBuyOrderSent(false);
        setBuyOrderError(response.data.text);
      }
    } catch(e) {
      console.log(e);
    }
    setDisabledBuyConfirmButton(false);
  }

  async function updateBuyOrder() {
    setDisabledUpdateBuyOrderButton(true);

    const data = {
      order_id: buyOrderID,
      to: selectedWallet.wallet_address,
      wallet_address: sendingDaiWalletAddress
    }

    try{
      const response = await axios.post(exchangeURL+'/api/orders/update_buy', data)
      console.log("sendSellOrder: ", response.data);
      if(response.data.status === 'ok') {
        setBuyModal(false);
      } else {
        
      }
    } catch(e) {
      console.log(e);
      await delay(2000);
      await updateBuyOrder();
    }

    setDisabledUpdateBuyOrderButton(false);
  }


  function convertTimestampToYmdHis(timestamp) {
    // Create a new Date object from the timestamp
    const date = new Date(timestamp * 1000);

    // Extract the date components
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);

    // Construct the formatted date string
    const formattedDate = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
    return formattedDate;
  }

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission
    
    // Additional logic here, like handling form data
  };

  async function updateTransactionDetail() {
    setDisabledUpdateTransactionButton(true);

    const data = {
      defcur_wallet: selectedWallet.wallet_address,
      order_id: selectedTransaction.id,
      wallet_address: sendingDaiWalletAddress
    }

    try{
      const response = await axios.post(exchangeURL+'/api/orders/update_transaction', data)
      console.log("updateTransactionDetail: ", response.data);
      if(response.data.status === 'ok') {
        await getOrdersList(active, selectedWallet.wallet_address)
        setOrderDetailsModal(false);
      } else {
        
      }
    } catch(e) {
      console.log(e);
      await delay(2000);
      await updateTransactionDetail();
    }

    setDisabledUpdateTransactionButton(false);
  }

  async function copyToClipboard(text) {
    try {
      await navigator.clipboard.writeText(text);
      setCopiedToClipboard(true);
      await delay(3000);
      setCopiedToClipboard(false);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  return (
    <>

      {(loadingWallets === true || loadingChartData === true || loadingCurrentPrice === true || loadingRestrictions === true) && (
        <div className="">
          <Badge variant="info light">Loading...</Badge>
        </div>
      )}

      {(loadingWallets === false && loadingChartData === false && loadingCurrentPrice === false && loadingRestrictions === false) && (
        <div className="row">

          <div className="col-xl-6 col-xxl-12">
            <div className="card">
              <div className="card-header d-sm-flex d-block pb-0 border-0">
                <div>
                  <h4 className="fs-20 text-black">Exchange</h4>
                  <p className="mb-0 fs-12">
                    DEFCUR / USD
                  </p>
                </div>
                <div className="d-flex mt-sm-0 mt-3">
                  {/* <div className="custom-control custom-switch toggle-switch text-right mr-4 mb-2">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="customSwitch11"
                    />
                    <label
                      className="custom-control-label fs-14 text-black pr-2"
                      htmlFor="customSwitch11"
                    >
                      Date
                    </label>
                  </div>
                  <div className="custom-control custom-switch toggle-switch text-right mr-4 mb-2">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="customSwitch12"
                    />
                    <label
                      className="custom-control-label fs-14 text-black pr-2"
                      htmlFor="customSwitch12"
                    >
                      Value
                    </label>
                  </div> */}

                  <div className="custom-control check-switch custom-checkbox mr-4 mb-2">
                    <input type="checkbox" className="custom-control-input" id="timeFrame1d" checked={timeFrame === '1d' ? true : false} onClick={() => changeTimeFrame('1d')} />
                    <label className="custom-control-label" htmlFor="timeFrame1d">
                      <span className="d-block text-black font-w500">1D</span>
                      <span className="fs-12">Daily</span>
                    </label>
                  </div>
                  <div className="custom-control check-switch custom-checkbox mr-4 mb-2">
                    <input type="checkbox" className="custom-control-input" id="timeFrame1M" checked={timeFrame === '1M' ? true : false} onClick={() => changeTimeFrame('1M')} />
                    <label className="custom-control-label" htmlFor="timeFrame1M">
                      <span className="d-block text-black font-w500">1M</span>
                      <span className="fs-12">Monthly</span>
                    </label>
                  </div>
                  
                </div>
              </div>
              <div className="card-body pb-0">
                <div className="d-flex flex-wrap">
                  
                </div>
                {/* <div id="lineChart" /> */}
                {/* {background.value === "dark" ? (
                  <CryptoStatisticsDark />
                ) : (
                  <CryptoStatistics />
                )} */}

                <div style={{opacity: changingTimeFrame === true ? 0.4 : 1}}>
                  {background.value === "dark" ? (
                    <TradingViewChart buyPoints={buyPoints} sellPoints={sellPoints} chartData={chartData} priceMin={0.0001} priceRoundNumber={4} theme='dark' />
                  ) : (
                    <TradingViewChart buyPoints={buyPoints} sellPoints={sellPoints} chartData={chartData} priceMin={0.0001} priceRoundNumber={4} theme='light' />
                  )}
                </div>
                
              </div>
            </div>
          </div>

          <div className="col-12">
            <Alert variant="info" className="alert-dismissible fade show">
              <b>Limitations.</b> {maxBuyCoins.toLocaleString('en-US')} DEFCUR Coins are available for purchase today.
            </Alert>
          </div>
          
          <div className="col-12 col-lg-6">
            <div className="card">
              <div className="card-header d-sm-flex d-block pb-0 border-0">
                <div>
                  <h4 className="fs-20 text-black">Sell</h4>
                  <p className="mb-0 fs-12">
                    Sell DEFCUR Coins
                  </p>
                </div>
                <Dropdown className="dropdown bootstrap-select form-control style-1 default-select mb-3">
                  <Dropdown.Toggle
                    variant=""
                    type="button"
                    className="btn dropdown-toggle btn-light"
                    data-toggle="dropdown"
                    role="button"
                    title="Latest"
                    aria-expanded="false"
                  >
                    <small>Balance: {selectedWallet.balance}. Wallet: ...{selectedWallet.wallet_address.substring(selectedWallet.wallet_address.length - 5)}</small>
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    className="dropdown-menu"
                    role="combobox"
                    x-placement="bottom-start"
                    style={{
                      maxHeight: 174,
                      overflow: "hidden",
                      minHeight: 0,
                      position: "absolute",
                      willChange: "transform",
                      top: 0,
                      left: 0,
                      transform: "translate3d(0px, 41px, 0px)",
                    }}
                  >
                    {wallets.length > 0 &&
                      wallets.map((item, key) => (
                        <Dropdown.Item key={key} onClick={() => {setSelectedWallet(item);}}>
                          Balance: {item.balance}. Wallet: {item.wallet_address.substring(0, 20)}......{item.wallet_address.substring(item.wallet_address.length - 20)}
                        </Dropdown.Item>
                      )
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <form className="form-wrapper" onSubmit={handleSubmit}>
                    <div className="form-group">
                      <div className="input-group input-group-lg">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            Amount, DEFCUR
                          </span>
                        </div>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="0.0"
                          onChange={(e) => setAmountSell(e.target.value)}
                          value={amountSell}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="input-group input-group-lg">
                        <div className="input-group-prepend">
                          <span className="input-group-text ">
                            Price, USD
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="0.0"
                          value={priceSell}
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="row mt-4">
                      {/* <div className="col-12">
                        <Link
                          to="/coin-details"
                          className="btn d-block btn-lg btn-success"
                        >
                          BUY
                          <svg
                            className="ml-4 scale3"
                            width={16}
                            height={16}
                            viewBox="0 0 21 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16.9638 11.5104L16.9721 14.9391L3.78954 1.7565C3.22815 1.19511 2.31799 1.19511 1.75661 1.7565C1.19522 2.31789 1.19522 3.22805 1.75661 3.78943L14.9392 16.972L11.5105 16.9637L11.5105 16.9637C10.7166 16.9619 10.0715 17.6039 10.0696 18.3978C10.0677 19.1919 10.7099 19.8369 11.5036 19.8388L11.5049 19.3388L11.5036 19.8388L18.3976 19.8554L18.4146 19.8555L18.4159 19.8555C18.418 19.8555 18.42 19.8555 18.422 19.8555C19.2131 19.8533 19.8528 19.2114 19.8555 18.4231C19.8556 18.4196 19.8556 18.4158 19.8556 18.4117L19.8389 11.5035L19.8389 11.5035C19.8369 10.7097 19.1919 10.0676 18.3979 10.0695C17.604 10.0713 16.9619 10.7164 16.9638 11.5103L16.9638 11.5104Z"
                              fill="white"
                              stroke="white"
                            />
                          </svg>
                        </Link>
                      </div> */}
                      <div className="col-12">
                        <button className="btn d-block btn-block btn-lg btn-danger" onClick={() => {setSellModal(true)}}>
                          SELL
                          <svg
                            className="ml-4 scale5"
                            width={16}
                            height={16}
                            viewBox="0 0 29 29"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.35182 13.4965L5.35182 13.4965L5.33512 6.58823C5.33508 6.5844 5.3351 6.58084 5.33514 6.57759M5.35182 13.4965L5.83514 6.58306L5.33514 6.58221C5.33517 6.56908 5.33572 6.55882 5.33597 6.5545L5.33606 6.55298C5.33585 6.55628 5.33533 6.56514 5.33516 6.57648C5.33515 6.57684 5.33514 6.57721 5.33514 6.57759M5.35182 13.4965C5.35375 14.2903 5.99878 14.9324 6.79278 14.9305C7.58669 14.9287 8.22874 14.2836 8.22686 13.4897L8.22686 13.4896L8.21853 10.0609M5.35182 13.4965L8.21853 10.0609M5.33514 6.57759C5.33752 5.789 5.97736 5.14667 6.76872 5.14454C6.77041 5.14452 6.77217 5.14451 6.77397 5.14451L6.77603 5.1445L6.79319 5.14456L13.687 5.16121L13.6858 5.66121L13.687 5.16121C14.4807 5.16314 15.123 5.80809 15.1211 6.6022C15.1192 7.3961 14.4741 8.03814 13.6802 8.03626L13.6802 8.03626L10.2515 8.02798L23.4341 21.2106C23.9955 21.772 23.9955 22.6821 23.4341 23.2435C22.8727 23.8049 21.9625 23.8049 21.4011 23.2435L8.21853 10.0609M5.33514 6.57759C5.33513 6.57959 5.33514 6.58159 5.33514 6.5836L8.21853 10.0609M6.77407 5.14454C6.77472 5.14454 6.77537 5.14454 6.77603 5.14454L6.77407 5.14454Z"
                              fill="white"
                              stroke="white"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div className="d-flex mt-3">
                      {/* <div className="custom-control custom-checkbox mr-3 mt-1">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customCheckBox1"
                          required
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customCheckBox1"
                        />
                      </div> */}
                      {/* <p className="mb-0">
                        <small>By creating an Order, you agree to the <Link to="#">Terms of service</Link>.</small>
                      </p> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <Modal className="fade" show={sellModal}>
            <Modal.Header>
              <Modal.Title>Create Sell Order</Modal.Title>
              <Button
                variant=""
                className="close"
                onClick={() => {setSellModal(false); setNotEnoughBalance(false);}}
              >
                <span>&times;</span>
              </Button>
            </Modal.Header>
            <Modal.Body>

              {sellWalletConfirmed === false && (
                <>
                  <span className="text-break"><b>From: </b> {selectedWallet.wallet_address}</span><br/><br/>
                  <b>Amount: </b> {amountSell} DEFCUR<br/>
                  <b>Price: </b> {priceSell} USD<br/>
                  <b>USD: </b> {Math.round(amountSell * priceSell * 100) / 100} USD<br/>
                  <hr/>

                  <div className="form-group">
                    <label className="mb-1 ">
                      <strong>Wallet Passcode</strong>
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="******"
                      onChange={(e) => {setPasscode(e.target.value); setVerifySellError('')}}
                      value={passcode}
                    />
                  </div>
                  <hr/>

                  {verifySellError !== '' && (
                    <Alert variant="danger" className="alert-dismissible fade show">
                      {verifySellError}
                    </Alert>
                  )}

                  <button className="btn btn-block btn-warning" onClick={() => verifyWalletPasscode('sell')} disabled={disabledSellNextButton}>
                    {disabledSellNextButton === false && (
                      <span>Next Step</span>
                    )}
                    {disabledSellNextButton === true && (
                      <span><img alt="" src={loadingGif} width="18" /></span>
                    )}
                  </button>
                </>
              )}

              {sellWalletConfirmed === true && sellOrderSent === false && (
                <>
                  <div className="form-group">
                    <label className="mb-1 ">
                      <strong><img alt="" src={DaiImage} width="29" /> Your DAI Wallet Address:</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder
                      onChange={(e) => {setUserDaiWalletAddress(e.target.value)}}
                      value={userDaiWalletAddress}
                    />
                  </div>

                  <div className="form-group text-left">
                    <label className="mb-1 ">
                      <strong>Select Network:</strong>
                    </label>
                    <select className="form-control text-center" value={DaiNetwork} onChange={(e) => setDaiNetwork(e.target.value)}>
                      <option value="eth" selected={DaiNetwork === 'eth' ? true : false}>ETH (ERC20)</option>
                    </select>
                  </div>

                  <b>Amount: </b> {Math.round(amountSell * priceSell * 100) / 100} DAI<br/>
                  <b>Commission: </b> {networkCommissionDai} DAI<br/>
                  <b>You will receive: </b> {(Math.round(amountSell * priceSell * 100) / 100 - networkCommissionDai).toFixed(2)} DAI<br/>

                  {notEnoughBalance === true && (
                    <Alert variant="danger" className="mt-2 alert-dismissible fade show">
                      There isn't enough balance to complete this transaction.
                    </Alert>
                  )}

                  <button className="mt-3 mb-3 btn btn-block btn-warning" onClick={() => sendSellOrder()} disabled={disabledSellConfirmButton}>
                    {disabledSellConfirmButton === false && (
                      <span>Confirm (Next Step {'>'})</span>
                    )}
                    {disabledSellConfirmButton === true && (
                      <span><img alt="" src={loadingGif} width="18" /></span>
                    )}
                  </button>
                  <hr/>

                  <div>
                    <b>Why do we use DAI cryptocurrency for exchanges?</b><br/>DAI is a stablecoin that doesn't have any blacklist and provides us with additional security for storing funds.
                    <br/><br/>
                    <b>Where can I get DAI coins?</b><br/>
                    <ul>
                      <li>* Centralized Exchanges: Binance, Coinbase, Kraken, Bitfinex</li>
                      <li>* Decentralized Exchanges: Uniswap, SushiSwap, Balancer</li>
                      <li>* P2P Platforms: LocalBitcoins, LocalCryptos</li>
                    </ul>
                  </div>
                  <hr/>

                </>
              )}

              {sellWalletConfirmed === true && sellOrderSent === true && (
                <>
                  <Alert variant="success" className="alert-dismissible fade show">
                    The order has been successfully created. Once completed, you will receive the DAI to your wallet address. Usually, it takes up to 15 minutes, but sometimes it can take up to 24 hours.
                  </Alert>
                </>
              )}

              {/* {sellOrderSent === true && (
                <Alert variant="success" className="alert-dismissible fade show">
                  The order has been successfully created.
                </Alert>
              )} */}

              {sellOrderError !== '' && (
                <Alert variant="danger" className="alert-dismissible fade show">
                  {sellOrderError}
                </Alert>
              )}

              

              <Button
                onClick={() => {setSellModal(false); setNotEnoughBalance(false);}}
                variant="info light"
                className="btn-block"
              >
                Close
              </Button>

            </Modal.Body>
          </Modal>

          <div className="col-12 col-lg-6">
            <div className="card">
              <div className="card-header d-sm-flex d-block pb-0 border-0">
                <div>
                  <h4 className="fs-20 text-black">Buy</h4>
                  <p className="mb-0 fs-12">
                    Buy DEFCUR Coins
                  </p>
                </div>
                <Dropdown className="dropdown bootstrap-select form-control style-1 default-select mb-3">
                  <Dropdown.Toggle
                    variant=""
                    type="button"
                    className="btn dropdown-toggle btn-light"
                    data-toggle="dropdown"
                    role="button"
                    title="Latest"
                    aria-expanded="false"
                  >
                    <small>Balance: {selectedWallet.balance}. Wallet: ...{selectedWallet.wallet_address.substring(selectedWallet.wallet_address.length - 5)}</small>
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    className="dropdown-menu"
                    role="combobox"
                    x-placement="bottom-start"
                    style={{
                      maxHeight: 174,
                      overflow: "hidden",
                      minHeight: 0,
                      position: "absolute",
                      willChange: "transform",
                      top: 0,
                      left: 0,
                      transform: "translate3d(0px, 41px, 0px)",
                    }}
                  >
                    {wallets.length > 0 &&
                      wallets.map((item, key) => (
                        <Dropdown.Item key={key+1000} onClick={() => {setSelectedWallet(item);}}>
                          Balance: {item.balance}. Wallet: {item.wallet_address.substring(0, 20)}......{item.wallet_address.substring(item.wallet_address.length - 20)}
                        </Dropdown.Item>
                      )
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <form className="form-wrapper" onSubmit={handleSubmit}>
                    <div className="form-group">
                      <div className="input-group input-group-lg">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            Amount, DEFCUR
                          </span>
                        </div>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="0.0"
                          onChange={(e) => {setAmountBuy(e.target.value); checkLimitBuy(e.target.value)}}
                          value={amountBuy}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="input-group input-group-lg">
                        <div className="input-group-prepend">
                          <span className="input-group-text ">
                            Price, USD
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="0.0"
                          value={priceBuy}
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-12">
                        <button className="btn d-block btn-block btn-lg btn-success" onClick={() => {setBuyModal(true)}} disabled={disabledBuyButton}>
                          BUY
                          <svg
                            className="ml-4 scale3"
                            width={16}
                            height={16}
                            viewBox="0 0 21 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16.9638 11.5104L16.9721 14.9391L3.78954 1.7565C3.22815 1.19511 2.31799 1.19511 1.75661 1.7565C1.19522 2.31789 1.19522 3.22805 1.75661 3.78943L14.9392 16.972L11.5105 16.9637L11.5105 16.9637C10.7166 16.9619 10.0715 17.6039 10.0696 18.3978C10.0677 19.1919 10.7099 19.8369 11.5036 19.8388L11.5049 19.3388L11.5036 19.8388L18.3976 19.8554L18.4146 19.8555L18.4159 19.8555C18.418 19.8555 18.42 19.8555 18.422 19.8555C19.2131 19.8533 19.8528 19.2114 19.8555 18.4231C19.8556 18.4196 19.8556 18.4158 19.8556 18.4117L19.8389 11.5035L19.8389 11.5035C19.8369 10.7097 19.1919 10.0676 18.3979 10.0695C17.604 10.0713 16.9619 10.7164 16.9638 11.5103L16.9638 11.5104Z"
                              fill="white"
                              stroke="white"
                            />
                          </svg>
                        </button>
                      </div>
                      {/* <div className="col-6">
                        <Link
                          to="/coin-details"
                          className="btn d-block btn-lg btn-danger"
                        >
                          SELL
                          <svg
                            className="ml-4 scale5"
                            width={16}
                            height={16}
                            viewBox="0 0 29 29"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.35182 13.4965L5.35182 13.4965L5.33512 6.58823C5.33508 6.5844 5.3351 6.58084 5.33514 6.57759M5.35182 13.4965L5.83514 6.58306L5.33514 6.58221C5.33517 6.56908 5.33572 6.55882 5.33597 6.5545L5.33606 6.55298C5.33585 6.55628 5.33533 6.56514 5.33516 6.57648C5.33515 6.57684 5.33514 6.57721 5.33514 6.57759M5.35182 13.4965C5.35375 14.2903 5.99878 14.9324 6.79278 14.9305C7.58669 14.9287 8.22874 14.2836 8.22686 13.4897L8.22686 13.4896L8.21853 10.0609M5.35182 13.4965L8.21853 10.0609M5.33514 6.57759C5.33752 5.789 5.97736 5.14667 6.76872 5.14454C6.77041 5.14452 6.77217 5.14451 6.77397 5.14451L6.77603 5.1445L6.79319 5.14456L13.687 5.16121L13.6858 5.66121L13.687 5.16121C14.4807 5.16314 15.123 5.80809 15.1211 6.6022C15.1192 7.3961 14.4741 8.03814 13.6802 8.03626L13.6802 8.03626L10.2515 8.02798L23.4341 21.2106C23.9955 21.772 23.9955 22.6821 23.4341 23.2435C22.8727 23.8049 21.9625 23.8049 21.4011 23.2435L8.21853 10.0609M5.33514 6.57759C5.33513 6.57959 5.33514 6.58159 5.33514 6.5836L8.21853 10.0609M6.77407 5.14454C6.77472 5.14454 6.77537 5.14454 6.77603 5.14454L6.77407 5.14454Z"
                              fill="white"
                              stroke="white"
                            />
                          </svg>
                        </Link>
                      </div> */}
                    </div>
                    <div className="d-flex mt-3">
                      {/* <div className="custom-control custom-checkbox mr-3 mt-1">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customCheckBox1"
                          required
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customCheckBox1"
                        />
                      </div> */}
                      {/* <p className="mb-0">
                        <small>By creating an Order, you agree to the <Link to="#">Terms of service</Link>.</small>
                      </p> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <Modal className="fade" show={buyModal}>
            <Modal.Header>
              <Modal.Title>Create Buy Order</Modal.Title>
              <Button
                variant=""
                className="close"
                onClick={() => {setBuyModal(false); setNotEnoughBalance(false);}}
              >
                <span>&times;</span>
              </Button>
            </Modal.Header>
            <Modal.Body>

              {buyWalletConfirmed === false && (
                <>
                  <span className="text-break"><b>To: </b> {selectedWallet.wallet_address}</span><br/><br/>
                  <b>Amount: </b> {amountBuy} DEFCUR<br/>
                  <b>Price: </b> {priceBuy} USD<br/>
                  <b>USD: </b> {Math.round(amountBuy * priceBuy * 100) / 100} USD<br/>
                  <hr/>

                  <div className="form-group">
                    <label className="mb-1 ">
                      <strong>Wallet Passcode</strong>
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="******"
                      onChange={(e) => {setPasscode(e.target.value)}}
                      value={passcode}
                    />
                  </div>
                  <hr/>

                  {verifyBuyError !== '' && (
                    <Alert variant="danger" className="alert-dismissible fade show">
                      {verifyBuyError}
                    </Alert>
                  )}

                  <button className="btn btn-block btn-warning" onClick={() => verifyWalletPasscode('buy')} disabled={disabledBuyNextButton}>
                    {disabledBuyNextButton === false && (
                      <span>Next Step</span>
                    )}
                    {disabledBuyNextButton === true && (
                      <span><img alt="" src={loadingGif} width="18" /></span>
                    )}
                  </button>

                </>
              )}

              {buyWalletConfirmed === true && buyOrderSent === false && (
                <>
                  <div className="form-group">
                    <label className="mb-1 ">
                      <strong><img alt="" src={DaiImage} width="29" /> Recipient's DAI Wallet Address:</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder
                      onChange={(e) => {setDaiWalletAddress(e.target.value)}}
                      value={daiWalletAddress}
                      disabled={true}
                    />
                    <p className="text-left">
                    {copiedToClipboard === false && (
                      <small onClick={() => copyToClipboard(daiWalletAddress)} style={{cursor: 'pointer', textDecoration: 'underline'}} className="">(Click to copy)</small>
                    )}
                    {copiedToClipboard === true && (
                      <small className="text-success">The DAI wallet address has been copied to the clipboard.<br/></small>
                    )}
                    </p>
                  </div>

                  <div className="form-group text-left">
                    <label className="mb-1 ">
                      <strong>Select Network:</strong>
                    </label>
                    <select className="form-control text-center" value={DaiNetwork} onChange={(e) => setDaiNetwork(e.target.value)}>
                      <option value="eth" selected={DaiNetwork === 'eth' ? true : false}>ETH (ERC20)</option>
                    </select>
                  </div>

                  <b>The recipient should receive: </b> {Math.round(amountBuy * priceBuy * 100) / 100} DAI<br/>

                  <button className="mt-3 mb-3 btn btn-block btn-warning" onClick={() => sendBuyOrder()} disabled={disabledBuyConfirmButton}>
                    {disabledBuyConfirmButton === false && (
                      <span>Confirm that I transferred the DAI (Next Step {'>'})</span>
                    )}
                    {disabledBuyConfirmButton === true && (
                      <span><img alt="" src={loadingGif} width="18" /></span>
                    )}
                  </button>

                  <Alert variant="warning" className="alert-dismissible fade show">
                    Important! If the recipient receives a different amount of coins, the transaction may process with significant delays. Please ensure that the recipient receives the exact amount of coins.
                  </Alert>

                  <hr/>

                  <div>
                    <b>Why do we use DAI cryptocurrency for exchanges?</b><br/>DAI is a stablecoin that doesn't have any blacklist and provides us with additional security for storing funds.
                    <br/><br/>
                    <b>Where can I get DAI coins?</b><br/>
                    <ul>
                      <li>* Centralized Exchanges: Binance, Coinbase, Kraken, Bitfinex</li>
                      <li>* Decentralized Exchanges: Uniswap, SushiSwap, Balancer</li>
                      <li>* P2P Platforms: LocalBitcoins, LocalCryptos</li>
                    </ul>
                  </div>
                  <hr/>

                </>
              )}

              {buyWalletConfirmed === true && buyOrderSent === true && (
                <>
                  <Alert variant="primary" className="alert-dismissible fade show">
                    To track your transaction on the blockchain, please provide the Transaction ID (TxID) for this transaction.
                  </Alert>

                  <div className="form-group">
                    <label className="mb-1 ">
                      <strong><img alt="" src={DaiImage} width="29" /> Transaction ID (TxID):</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder
                      onChange={(e) => {setSendingDaiWalletAddress(e.target.value)}}
                      value={sendingDaiWalletAddress}
                    />
                  </div>

                  <button className="mt-3 mb-3 btn btn-block btn-warning" onClick={() => updateBuyOrder()} disabled={disabledUpdateBuyOrderButton}>
                    {disabledUpdateBuyOrderButton === false && (
                      <span>Save</span>
                    )}
                    {disabledUpdateBuyOrderButton === true && (
                      <span><img alt="" src={loadingGif} width="18" /></span>
                    )}
                  </button>
                </>
              )}

              {notEnoughBalance === true && (
                <Alert variant="danger" className="alert-dismissible fade show">
                  There isn't enough balance to complete this transaction.
                </Alert>
              )}

              {buyOrderError !== '' && (
                <Alert variant="danger" className="alert-dismissible fade show">
                  {buyOrderError}
                </Alert>
              )}

              <Button
                onClick={() => {setBuyModal(false); setNotEnoughBalance(false);}}
                variant="info light"
                className="btn-block"
              >
                Close
              </Button>

            </Modal.Body>
          </Modal>

          <div className="col-xl-12">
            <div className="table-responsive table-hover fs-14 ">

              <div id="example6_wrapper" className="dataTables_wrapper no-footer">
                <table
                  className="table display mb-4 dataTablesCard font-w600 short-one border-no card-table text-black dataTable no-footer"
                  id="marketCapital"
                  role="grid"
                  aria-describedby="example6_info"
                >
                  <thead>
                    <tr role="row">
                      <th className="sorting_asc">Date</th>
                      <th className="sorting_asc">Side</th>
                      <th className="sorting_asc">Wallet</th>
                      <th className="sorting_asc">Price</th>
                      <th className="sorting_asc">USD</th>
                      <th className="sorting_asc">Amount</th>
                      <th className="sorting_asc">Status</th>
                      <th className="sorting_asc">Action</th>
                    </tr>
                  </thead>
                  <tbody>

                    {orderList.length > 0 &&
                      orderList.map((item, key) => (
                        <tr key={key+2000} role="row" className="odd">
                          <td className="wspace-no sorting_1">
                            {convertTimestampToYmdHis(item.timestamp)}
                          </td>
                          <td className="wspace-no sorting_1">
                            {item.side === 'buy' && (
                              <span className="text-success">BUY</span>
                            )}
                            {item.side === 'sell' && (
                              <span className="text-danger">SELL</span>
                            )}
                          </td>
                          <td className="wspace-no sorting_1">
                            {item.defcur_wallet.substring(0, 10) + "............" + item.defcur_wallet.substring(item.defcur_wallet.length - 10)}
                          </td>
                          <td className="wspace-no sorting_1">
                            {item.price} USD
                          </td>
                          <td className="wspace-no sorting_1">
                            {item.usd} USD
                          </td>
                          <td className="wspace-no sorting_1">
                            {item.amount / 100000000} DEFCUR
                          </td>
                          <td className="wspace-no sorting_1">
                            {item.status === 'pending' && (
                              <Badge variant="info light">Pending</Badge>
                            )}
                            {item.status === 'detected' && (
                              <Badge variant="warning light">Detected on the blockchain</Badge>
                            )}
                            {item.status === 'completed' && (
                              <Badge variant="success light">Completed</Badge>
                            )}
                          </td>
                          <td className="wspace-no sorting_1">
                            <button className="btn btn-xs btn-info" onClick={() => {setSelectedTransaction(item); setSendingDaiWalletAddress(item.wallet_address); setOrderDetailsModal(true)}}>Details</button>
                          </td>
                        </tr>
                      )
                    )}


                  </tbody>
                </table>
              </div>

            </div>

            <Modal className="fade" show={orderDetailsModal}>
              <Modal.Header>
                <Modal.Title>Order Details</Modal.Title>
                <Button
                  variant=""
                  className="close"
                  onClick={() => {setOrderDetailsModal(false)}}
                >
                  <span>&times;</span>
                </Button>
              </Modal.Header>
              <Modal.Body>

                <span className="text-break"><b>Order Type: </b> {selectedTransaction.side.toUpperCase()}</span><br/><br/>
                <span className="text-break"><b>DEFCUR Wallet: </b> {selectedTransaction.defcur_wallet}</span><br/><br/>
                <b>Amount: </b> {selectedTransaction.amount / 100000000} DEFCUR<br/>
                <b>Price: </b> {selectedTransaction.price} USD<br/>
                <b>USD: </b> {Math.round(selectedTransaction.usd * 100) / 100} USD<br/>
                <hr/>

                {selectedTransaction.status === 'pending' && (
                  <>
                    <div className="form-group">
                      <label className="mb-1 ">
                        <strong><img alt="" src={DaiImage} width="29" /> Transaction ID (TxID):</strong>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        onChange={(e) => {setSendingDaiWalletAddress(e.target.value)}}
                        value={sendingDaiWalletAddress}
                      />
                    </div>
                  

                    <Button
                      onClick={() => {updateTransactionDetail()}}
                      variant="info"
                      className="btn-block"
                      disabled={disabledUpdateTransactionButton}
                    >
                      {disabledUpdateTransactionButton === false && (
                        <span>Transaction ID (TxID)</span>
                      )}
                      {disabledUpdateTransactionButton === true && (
                        <span><img alt="" src={loadingGif} width="18" /></span>
                      )}
                    </Button>
                    <hr/>
                  </>
                )}

                <Button
                  onClick={() => {setOrderDetailsModal(false);}}
                  variant="info light"
                  className="btn-block"
                >
                  Close
                </Button>

              </Modal.Body>
            </Modal>

            <div className="">
              <Pagination
                size="sm"
                className={`mt-4 pagination-gutter pagination-primary no-bg pagination-circle`}
              >
                <li className="page-item page-indicator">
                  <Link className="page-link" to="#" onClick={() => changePagePrev()}>
                    <i className="la la-angle-left" />
                  </Link>
                </li>

                {pageItems.length > 0 &&
                  pageItems.map((item, i) => (
                    <Pagination.Item key={i} active={item.p === active ? true : false} onClick={() => {changePage(item.p)}}>
                      {item.p}
                    </Pagination.Item>
                  )
                )}
                
                <li className="page-item page-indicator">
                  <Link className="page-link" to="#" onClick={() => changePageNext()}>
                    <i className="la la-angle-right" />
                  </Link>
                </li>
              </Pagination>
              
            </div>

            {loadingBadge === true && (
              <Badge variant="info light">Loading...</Badge>
            )}

          </div>

        </div>
      )}
    </>
  );
};

export default BuySell;
