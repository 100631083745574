import React, { useEffect } from "react";

/// Components
import Markup from "./jsx";

/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";

// import { withResizeDetector } from "react-resize-detector";

import ThemeContextProvider from "./context/ThemeContext";

// import { useLocation } from 'react-router-dom';

// import axios from 'axios';
// import {baseURL} from './baseURL';
import Cookies from 'js-cookie';

const App = ({ width }) => {
  // const body = document.querySelector("body");
  // useEffect(() => {
  //   body.setAttribute("data-typography", "poppins");
  //   body.setAttribute("data-theme-version", "light");
  //   body.setAttribute("data-layout", "vertical");
  //   body.setAttribute("data-nav-headerbg", "color_1");
  //   body.setAttribute("data-headerbg", "color_1");
  //   body.setAttribute("data-sidebar-style", "overlay");
  //   body.setAttribute("data-sibebarbg", "color_1");
  //   body.setAttribute("data-primary", "color_1");
  //   body.setAttribute("data-sidebar-position", "fixed");
  //   body.setAttribute("data-header-position", "fixed");
  //   body.setAttribute("data-container", "wide");
  //   body.setAttribute("direction", "ltr");

  //   width >= 768 && width < 1300
  //     ? body.setAttribute("data-sidebar-style", "mini")
  //     : width <= 768
  //     ? body.setAttribute("data-sidebar-style", "overlay")
  //     : body.setAttribute("data-sidebar-style", "full");
  // }, [width]);

  // const location = useLocation();
  // let path = window.location.pathname;
  // path = path.split("/");
  // var location = path[path.length - 1];
  // location = location.split("?")[0];
  
  useEffect(() => {

    const urlParams = new URLSearchParams(window.location.search);
    const ref = urlParams.get('r')
    if(ref != null) {
      Cookies.set('ref', ref, { expires: 30, path: '/'});
    }
    
    // eslint-disable-next-line
  }, []);

  return (
    <ThemeContextProvider>
      <Markup />
    </ThemeContextProvider>
  );
};

// export default withResizeDetector(App);
export default App;
