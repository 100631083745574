import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import { Alert } from "react-bootstrap";

// image
// import logo from "../../images/logo/logo_full.png";
import login_icon from "../../images/logo/login_icon.png";
import create_icon from "../../images/logo/create_icon.png";
// import loadingGif from "../../images/loading.gif";


// import axios from 'axios';
// import { baseURL } from "../../baseURL";
import Cookies from 'js-cookie';

const Login = ({ history }) => {
  // const [email, setEmail] = useState('');
  // const [password, setPassword] = useState('');
  // const [error, setError] = useState('');
  // const [disabledButton, setDisabledButton] = useState(false);
  const [showDashboardButton, setShowDashboardButton] = useState(false);

  // function isValidEmail(email) {
  //   // Regular expression for email validation
  //   var emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   return emailPattern.test(email);
  // }

  useEffect(() => {
    getWallets();
    // eslint-disable-next-line
  }, []);

  async function getWallets() {
    const wallets_ = Cookies.get('wallets');
    if(wallets_ === undefined || wallets_ === "undefined") {
      
    } else {
      const wallets_all = JSON.parse(wallets_);
      if(wallets_all.length > 0) {
        setShowDashboardButton(true);
      }
    }
  }

  const submitHandler = async (e) => {
    e.preventDefault();
  };

  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        <div className="row col-12 col-md-8 col-lg-7 mx-auto">
          <div className="col-md-12 mt-5">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3">
                      <Link to="/">
                        <img src={create_icon} width="60" alt="" />
                      </Link>
                    </div>
                    <h4 className="text-center mb-4 ">Create New Wallet</h4>

                    {/* {error !== "" && (
                      <Alert variant="danger" className="alert-dismissible fade show">
                        {error}
                      </Alert>
                    )} */}

                    <form onSubmit={(e) => submitHandler(e)}>
                      <div className="text-center">
                        <Link to="./page-create-wallet">
                          <button type="submit" className="btn btn-info btn-block justify-content-center align-items-center h-100" >
                            <span>Create Wallet</span>
                          </button>
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12 mt-5">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3">
                      <Link to="/">
                        <img src={login_icon} width="60" alt="" />
                      </Link>
                    </div>
                    <h4 className="text-center mb-4 ">Login to Your Wallet</h4>

                    {/* {error !== "" && (
                      <Alert variant="danger" className="alert-dismissible fade show">
                        {error}
                      </Alert>
                    )} */}

                    <div className="text-center">
                      <Link to="./page-login-wallet">
                        <button type="submit" className="btn btn-primary btn-block justify-content-center align-items-center h-100" >
                          <span>Log In</span>
                        </button>
                      </Link>
                    </div>

                    {showDashboardButton === true && (
                      <div className="text-center">
                        <Link to="./">
                          <button type="submit" className="mt-3 btn btn-outline-primary btn-block justify-content-center align-items-center h-100">
                            <span>Open Dashboard</span>
                          </button>
                        </Link>
                      </div>
                    )}

                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default Login;
