import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Alert } from "react-bootstrap";

// image
// import logo from "../../images/logo/logo_full.png";
import login_icon from "../../images/logo/login_icon.png";
// import create_icon from "../../images/logo/create_icon.png";
import loadingGif from "../../images/loading.gif";


import axios from 'axios';
import { baseURL } from "../../baseURL";
import Cookies from 'js-cookie';

const Login = ({ history }) => {
  const [passcode, setPasscode] = useState('');
  const [error, setError] = useState('');
  const [disabledButton, setDisabledButton] = useState(false);

  // const [creatingWallet, setCreatingWallet] = useState(false);
  // const [createdWallet, setCreatedWallet] = useState(false);

  const [selectedFile, setSelectedFile] = useState('Choose file');
  const [fileContent, setFileContent] = useState('');
  const [walletText, setWalletText] = useState('');
  

  const handleFileChange = (e) => {
    // setSelectedFile(e.target.files[0]);
    setSelectedFile(e.target.files[0].name);
    console.log(e.target.files[0]);

    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const content = event.target.result;
      console.log("content: ", content);
      setFileContent(content);
    };

    reader.readAsText(file);
  };

  function removeDuplicatesByWalletAddress(arr) {
    const seen = new Set();
    const result = [];

    for (const item of arr) {
      if (!seen.has(item.wallet_address)) {
        seen.add(item.wallet_address);
        result.push(item);
      }
    }

    return result;
  };

  async function loginWallet() {
    setDisabledButton(true);

    if(passcode.length === 0) {
      setError("The Passcode field is blank.")
      setDisabledButton(false);
      return "";
    }

    if(passcode.length !== 16 && passcode.length !== 32) {
      setError("The passcode length can be either 16 or 32 characters.")
      setDisabledButton(false);
      return "";
    }

    var file_content = walletText === "" ? fileContent : walletText;
    try {
      file_content = JSON.parse(file_content);
    } catch (error) {}

    // if(walletText == "") {
    //   file_content = fileContent;
    //   try {
    //     file_content = JSON.parse(fileContent);
    //   } catch (error) {}
    // } else {
    //   file_content = walletText;
    //   try {
    //     file_content = JSON.parse(walletText);
    //   } catch (error) {}
    // }

    console.log("file_content: ", file_content);

    const data = {
      passcode: passcode,
      wallet: file_content
    }

    try {
      const response = await axios.post(baseURL+'/check_wallet', data);
      console.log("result: ", response.data)
      if(response.data.status === 'ok') {
        // Cookies.set('token', response.data.token, { expires: 1, path: '/'});
        // Cookies.set('email', response.data.email, { expires: 1, path: '/'});
        // history.push("/");
        // setCreatedWallet(true);

        var wallets = Cookies.get('wallets');
        var wallets_all = "undefined";
        if(wallets === undefined || wallets === "undefined") {
          wallets_all = [{
            "wallet": file_content,
            "wallet_address": response.data.wallet_address
          }];
          wallets_all = removeDuplicatesByWalletAddress(wallets_all);
          Cookies.set('wallets', JSON.stringify(wallets_all), { expires: 30, path: '/'});
        } else {
          wallets_all = JSON.parse(wallets);
          wallets_all.push({
            "wallet": file_content,
            "wallet_address": response.data.wallet_address
          })
          wallets_all = removeDuplicatesByWalletAddress(wallets_all);
          Cookies.set('wallets', JSON.stringify(wallets_all), { expires: 30, path: '/'});
        }

        window.location.href = '/';
        
      } else {
        setError(response.data.text);
      }
    } catch(err) {
      console.log(err);
    }
    
    setDisabledButton(false);
    // setCreatingWallet(false);

    // history.push("/");
  };

  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        <div className="row col-12 col-md-8 col-lg-7 mx-auto">
          <div className="col-md-12 mt-5">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3">
                      <Link to="/">
                        <img src={login_icon} width="60" alt="" />
                      </Link>
                    </div>
                    <h4 className="text-center mb-4 ">Login to Your Wallet</h4>

                    {error !== "" && (
                      <Alert variant="danger" className="alert-dismissible fade show">
                        {error}
                      </Alert>
                    )}

                    <div className="form-group">
                      <label className="mb-1 ">
                        <strong>Passcode (length: {passcode.length})</strong>
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="******"
                        onChange={(e) => {setPasscode(e.target.value); setError('')} }
                      />
                    </div>
                    <hr/>

                    <div className="form-group">
                      <label className="mb-1 ">
                        <strong>Upload Wallet File</strong>
                      </label>
                      
                      <div className="input-group">
                        <div className="custom-file">
                          <input type="file" className="custom-file-input" onChange={handleFileChange} />
                          <label className="custom-file-label">{selectedFile}</label>
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="mb-1 ">
                        <strong>Or paste the wallet data (text from your wallet file):</strong>
                      </label>
                      <textarea
                        type="text"
                        className="form-control"
                        placeholder=""
                        rows={10}
                        onChange={(e) => {setWalletText(e.target.value); setError('')} }
                      />
                    </div>

                    <div className="text-center">
                      <button type="submit" className="btn btn-primary btn-block justify-content-center align-items-center h-100" disabled={disabledButton} onClick={() => loginWallet()} >
                        {disabledButton === false && (
                          <span>Log In</span>
                        )}
                        {disabledButton === true && (
                          <span><img alt="" src={loadingGif} width="18" /></span>
                        )}
                      </button>
                    </div>



                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default Login;
