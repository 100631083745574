import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Alert } from "react-bootstrap";

// image
// import logo from "../../images/logo/logo_full.png";
// import login_icon from "../../images/logo/login_icon.png";
import create_icon from "../../images/logo/create_icon.png";
import loadingGif from "../../images/loading.gif";


import axios from 'axios';
import { baseURL } from "../../baseURL";
import Cookies from 'js-cookie';

const Login = ({ history }) => {
  const [passcode, setPasscode] = useState('');

  const [wallet, setWallet] = useState('');
  const [walletJsonToText, setWalletJsonToText] = useState('');
  const [walletAddress, setWalletAddress] = useState('');

  const [error, setError] = useState('');
  const [disabledButton, setDisabledButton] = useState(false);

  const [creatingWallet, setCreatingWallet] = useState(false);
  const [createdWallet, setCreatedWallet] = useState(false);

  const [downloadWallet, setDownloadWallet] = useState(false);
  

  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  async function createWallet() {
    setDisabledButton(true);

    if(passcode.length === 0) {
      setError("The Passcode field is blank.")
      setDisabledButton(false);
      return "";
    }

    if(passcode.length !== 16 && passcode.length !== 32) {
      setError("The passcode length can be either 16 or 32 characters.")
      setDisabledButton(false);
      return "";
    }

    setCreatingWallet(true);

    const data = {
      passcode: passcode
    }

    try {
      const response = await axios.post(baseURL+'/create_wallet', data);
      console.log(response.data);
      if(response.data.status === 'ok') {
        // Cookies.set('token', response.data.token, { expires: 1, path: '/'});
        // Cookies.set('email', response.data.email, { expires: 1, path: '/'});
        // history.push("/");

        // await delay(5000);

        setWallet(response.data.wallet);
        setWalletJsonToText(JSON.stringify(response.data.wallet));
        setWalletAddress(response.data.wallet_address);

        setCreatedWallet(true);
      } else {
        setError(response.data.text);
      }
    } catch(err) {

    }
    
    setDisabledButton(false);
    setCreatingWallet(false);

    // history.push("/");
  };

  const downloadWalletFile = async () => {
    const jsonString = JSON.stringify(wallet, null, 2);
    const blob = new Blob([jsonString], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'wallet.json'; // Specify your desired file name here
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);

    await delay(3000);
    setDownloadWallet(true);
  };

  async function loginToWallet() {

    var wallets = Cookies.get('wallets');
    console.log("wallets: ", wallets);

    if(wallets === undefined || wallets === "undefined") {
      const wallets_all = [{
        "wallet": wallet,
        "wallet_address": walletAddress
      }];

      Cookies.set('wallets', JSON.stringify(wallets_all), { expires: 30, path: '/'});
    } else {
      var wallets_all = JSON.parse(wallets);
      wallets_all.push({
        "wallet": wallet,
        "wallet_address": walletAddress
      })
      Cookies.set('wallets', JSON.stringify(wallets_all), { expires: 30, path: '/'});
    }

    window.location.href = '/';

  }

  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        <div className="row col-12 col-md-8 col-lg-7 mx-auto">
          {creatingWallet === false && createdWallet === false && (
            <div className="col-md-12 mt-5">
              <div className="authincation-content">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form">
                      <div className="text-center mb-3">
                        <Link to="/">
                          <img src={create_icon} width="60" alt="" />
                        </Link>
                      </div>
                      <h4 className="text-center">Create New Wallet</h4>
                      <p className="text-center mb-4 ">
                        <span>Please enter the passcode and click the "Create" button to create a new wallet.</span>
                      </p>

                      <Alert variant="warning" className="alert-dismissible fade show">
                        The passcode length can be either 16 or 32 characters.
                      </Alert>

                      {error !== "" && (
                        <Alert variant="danger" className="alert-dismissible fade show">
                          {error}
                        </Alert>
                      )}

                      <div className="form-group">
                        <label className="mb-1 ">
                          <strong>Passcode (length: {passcode.length}) </strong>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="******"
                          onChange={(e) => {setPasscode(e.target.value); setError('')} }
                        />
                      </div>
                      
                      <div className="text-center">
                        <button type="submit" className="btn btn-info btn-block justify-content-center align-items-center h-100" disabled={disabledButton} onClick={() => createWallet()} >
                          {disabledButton === false && (
                            <span>Create</span>
                          )}
                          {disabledButton === true && (
                            <span><img alt="" src={loadingGif} width="18" /></span>
                          )}
                        </button>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {creatingWallet === true && createdWallet === false && (
            <div className="col-md-12 mt-5">
              <div className="authincation-content">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form">
                      <div className="text-center mb-3">
                        <Link to="/">
                          <img src={create_icon} width="60" alt="" />
                        </Link>
                      </div>
                      <h4 className="text-center mb-4 ">Wallet creation is in progress...</h4>

                      <div className="text-center">
                        <button type="submit" className="btn btn-info btn-block justify-content-center align-items-center h-100" disabled={true} >
                          <span><img alt="" src={loadingGif} width="18" /></span>
                        </button>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {creatingWallet === false && createdWallet === true && (
            <div className="col-md-12 mt-5">
              <div className="authincation-content">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form">
                      <div className="text-center mb-3">
                        <Link to="/">
                          <img src={create_icon} width="60" alt="" />
                        </Link>
                      </div>
                      <h4 className="text-center mb-4">The wallet has been successfully created</h4>
                      
                      {error !== "" && (
                        <Alert variant="danger" className="alert-dismissible fade show">
                          {error}
                        </Alert>
                      )}

                      <Alert variant="warning" className="alert-dismissible fade show">
                        Please download and save this wallet file in a secure place and remember your passcode. If you lose the passcode, it will not be possible to restore access to your wallet.
                      </Alert>

                      
                      <div className="text-center">
                        <button type="submit" className="btn btn-info btn-block justify-content-center align-items-center h-100" disabled={disabledButton} onClick={() => downloadWalletFile()} >
                          {disabledButton === false && (
                            <span>Download Wallet</span>
                          )}
                          {disabledButton === true && (
                            <span><img alt="" src={loadingGif} width="18" /></span>
                          )}
                        </button>
                      </div>
                      <hr/>

                      <div className="form-group text-center">
                      <label className="mb-1 mt-4">
                        <strong>Or save the following wallet data in a secure place</strong>
                      </label>
                        <textarea
                          type="text"
                          className="form-control"
                          placeholder=""
                          rows={10}
                          value={walletJsonToText}
                        />
                      </div>

                      <div className="text-center">
                        <button type="submit" className="mt-3 btn btn-outline-info btn-block justify-content-center align-items-center h-100" disabled={disabledButton} onClick={() => setDownloadWallet(true)} >
                          {disabledButton === false && (
                            <span>I have saved the wallet data</span>
                          )}
                          {disabledButton === true && (
                            <span><img alt="" src={loadingGif} width="18" /></span>
                          )}
                        </button>
                      </div>

                      {downloadWallet === true && (
                        <div className="text-center">
                          <button type="submit" className="mt-3 btn btn-outline-info btn-block justify-content-center align-items-center h-100" disabled={disabledButton} onClick={() => loginToWallet()} >
                            {disabledButton === false && (
                              <span>Login to wallet</span>
                            )}
                            {disabledButton === true && (
                              <span><img alt="" src={loadingGif} width="18" /></span>
                            )}
                          </button>
                        </div>
                      )}

                      {/* <p className="text-center mb-4 ">
                        <small>IMPORTANT: Save this file in a secure place, and we recommend encrypting it to prevent theft of funds.</small>
                      </p> */}

                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          
        </div>
      </div>
    </div>
  );
};

export default Login;
