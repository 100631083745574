import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import { Badge, Dropdown, OverlayTrigger, Popover, Button, Alert } from "react-bootstrap";
import {SetBackgroundTheme} from "../../setBackgroundTheme";
import axios from 'axios';
import { baseURL } from "../../../baseURL";
import Cookies from 'js-cookie';

const Exchanges = () => {
  const [exchanges, setExchanges] = useState([]);
  const [, setUpdateIntervalID] = useState(null);
  const [loading, setLoading] = useState(true);

  const [licenseType, setLicenseType] = useState('');
  const [licenseActive, setLicenseActive] = useState('');
  const [freeTrialActive, setFreeTrialActive] = useState(0);
  
  const { changeBackground } = useContext(ThemeContext);
  useEffect(async () => {
    const reload_exchanges = await getExchanges();
    if(reload_exchanges === 'yes') {
      // Set interval to run the function every minute
      const intervalId = setInterval(getExchanges, 10000); // 60000 milliseconds = 1 minute
      setUpdateIntervalID(intervalId);
      // Cleanup function to clear the interval when the component unmounts
      return () => clearInterval(intervalId);
    }

    SetBackgroundTheme(changeBackground, Cookies);
    
  }, []);

  async function getExchanges() {
    var reload_exchanges = "no";
    const data = {
      token: Cookies.get('token')
    }

    try {
      const response = await axios.post(baseURL+'/api/exchange/get_all', data);
      if(response.data.status === 'ok') {
        setExchanges(response.data.exchanges);

        for(const e of response.data.exchanges) {
          if(e.status === 'checking') {
            reload_exchanges = "yes";
          }
        }

      } else {
        // setError(response.data.text);
      }
    } catch(err) {

    }

    setLoading(false);

    return reload_exchanges;
  }

  return (
    <>

      <div className="row">
        <div className="col-12">
          <div className="card overflow-hidden">
            <div className="card-header d-block d-sm-flex border-0">
              <div>
                <h4 className="fs-20 text-black">Currently in development</h4>
                <p className="mb-0 fs-12">
                  It will be available soon.
                </p>
              </div>
              <div className=" mt-3 mt-sm-0">
                {/* {(licenseType === 'percent' || licenseActive === 1 || freeTrialActive == 1) && (
                  <Link to="/exchanges/connect" className="btn btn-sm btn-info">Connect Exchange</Link>
                )}
                {(licenseType === 'lifetime' && licenseActive === 0 && freeTrialActive == 0) && (
                  <Link to="/license" className="btn btn-sm btn-info">Activate Lifetime License</Link>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 
      {loading === true && (
        <Badge variant="info light">Loading...</Badge>
      )} */}

    </>
  );
};

export default Exchanges;
