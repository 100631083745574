import React, { useState, useEffect } from "react";
import { Dropdown, Button, Alert, Modal } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import { ThemeContext } from "../../../context/ThemeContext";

import axios from 'axios';
import { baseURL } from "../../../baseURL";
import Cookies from 'js-cookie';

// import {SetBackgroundTheme} from "../../setBackgroundTheme";

const Transfer = () => {
  const [wallets, setWallets] = useState([]);
  const [selectedWallet, setSelectedWallet] = useState({wallet_address: "", balance: 0});
  const [passcode, setPasscode] = useState('');
  const [reference, setReference] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [notEnoughBalance, setNotEnoughBalance] = useState(false);
  
  
  

  const [recipientWalletAddress, setRecipientWalletAddress] = useState('');
  const [amountTransfer, setAmountTransfer] = useState(0);
  const [fee, setFee] = useState(0);
  const [amountToReceive, setAmountToReceive] = useState(0);
  
  
              

  // const [activaTableName, setActivaTableName] = useState('closed');
  // const [closedPositions, setClosedPositions] = useState([]);
  // const [openPositions, setOpenPositions] = useState([]);

  // const [loadingClosedPositions, setLoadingClosedPositions] = useState(true);
  // const [loadingOpenPositions, setLoadingOpenPositions] = useState(true);

  const [basicModal, setBasicModal] = useState(false);
  const [transferSent, setTransferSent] = useState(false);
  const [transferError, setTransferError] = useState('');
  const [disabledTransferButton, setDisabledTransferButton] = useState(false);
  

  // const [active, setActive] = useState(1);
  // const [maxPage, setMaxPage] = useState(2);
  // const [pageItems, setPageItems] = useState([{p:1}]);
  // const [loadingBadge, setLoadingBadge] = useState(false);
  // const [maxAvailablePage, setMaxAvailablePage] = useState(1);

  // const { changeBackground } = useContext(ThemeContext);
  useEffect(() => {
    getWallets();

    // getClosedPositions(1);
    // getOpenPositions();
    // SetBackgroundTheme(changeBackground, Cookies);

    // Set interval to run the function every minute
    // const intervalId = setInterval(getOpenPositions, 10000); // 60000 milliseconds = 1 minute
    // Cleanup function to clear the interval when the component unmounts
    // return () => clearInterval(intervalId);
  }, []);

  async function getWallets() {
    const wallets_ = Cookies.get('wallets');
    if(wallets_ === undefined || wallets_ === "undefined") {
      window.location.href = '/page-login';
    } else {
      var wallets_all = JSON.parse(wallets_);
      for(const w of wallets_all) {
        // if(w['balance'] == undefined) {
        //   try{ 
        //     w['balance'] = await getWalletBalance(w['wallet_address']);
        //   } catch(e) {
        //     w['balance'] = 0;
        //   }
        // }
        w['balance'] = await getWalletBalance(w['wallet_address']);
        setSelectedWallet(w);
      }
      setWallets(wallets_all);

    }
  }

  async function getWalletBalance(wallet_address) {
    var balance = 0;
    const data = { wallet_address: wallet_address }
    const response = await axios.post(baseURL+'/get_wallet_balance', data)
    if(response.data.status === 'ok') {
      balance = response.data.balance;
    }
    return balance;
  }

  async function sendTransferRequest() {
    setTransferError('');
    setDisabledTransferButton(true);

    const balance_new = parseFloat(selectedWallet.balance) - parseFloat(amountTransfer);
    if(balance_new < 0) {
      setNotEnoughBalance(true);
      setDisabledTransferButton(false);
      return ""
    }
    setNotEnoughBalance(false);

    const data = {
      wallet: selectedWallet.wallet,
      passcode: passcode,
      from: selectedWallet.wallet_address,
      to: recipientWalletAddress,
      amount: amountTransfer,
      reference: reference
    }

    try{
      const response = await axios.post(baseURL+'/create_new_pending_transaction', data)
      console.log("sendTransferRequest: ", response.data);
      if(response.data.status === 'ok') {
        setTransferSent(true);
      } else {
        setTransferSent(false);
        setTransferError(response.data.text);
      }
    } catch(e) {
      await delay(1000);
      await sendTransferRequest();
    }
    setDisabledTransferButton(false);
  }

  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  function changeAmountTransfer(amount) {
    var fee_ = parseFloat(amount) * 0.001;
    fee_ = Math.ceil(fee_ * 1e6) / 1e6;
    fee_ = Math.ceil(fee_ * 1e5) / 1e5;
    fee_ = Math.ceil(fee_ * 1e4) / 1e4;

    var amount_to_receive = parseFloat(amount) - fee_;
    amount_to_receive = Math.ceil(amount_to_receive * 1e6) / 1e6;
    amount_to_receive = Math.ceil(amount_to_receive * 1e5) / 1e5;
    amount_to_receive = Math.ceil(amount_to_receive * 1e4) / 1e4;

    var amount_ = parseFloat(amount);
    amount_ = Math.ceil(amount_ * 1e6) / 1e6;
    amount_ = Math.ceil(amount_ * 1e5) / 1e5;
    amount_ = Math.ceil(amount_ * 1e4) / 1e4;

    setAmountTransfer(amount_);
    setFee(fee_);
    setAmountToReceive(amount_to_receive);

    if(parseFloat(amount) > 0) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }

  return (
    <>

      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header d-sm-flex d-block pb-0 border-0">
              <div>
                <h4 className="fs-20 text-black">Transfer</h4>
                <p className="mb-0 fs-12">
                  Make payments
                </p>
              </div>
              <Dropdown className="dropdown bootstrap-select form-control style-1 default-select mb-3">
                <Dropdown.Toggle
                  variant=""
                  type="button"
                  className="btn dropdown-toggle btn-light"
                  data-toggle="dropdown"
                  role="button"
                  title="Latest"
                  aria-expanded="false"
                >
                  Balance: {selectedWallet.balance}. Wallet: {selectedWallet.wallet_address.substring(0, 5)}......{selectedWallet.wallet_address.substring(selectedWallet.wallet_address.length - 5)}
                </Dropdown.Toggle>
                <Dropdown.Menu
                  className="dropdown-menu"
                  role="combobox"
                  x-placement="bottom-start"
                  style={{
                    maxHeight: 174,
                    overflow: "hidden",
                    minHeight: 0,
                    position: "absolute",
                    willChange: "transform",
                    top: 0,
                    left: 0,
                    transform: "translate3d(0px, 41px, 0px)",
                  }}
                >
                  {wallets.length > 0 &&
                    wallets.map((item, key) => (
                      <Dropdown.Item key={key} onClick={() => {setSelectedWallet(item)}}>
                        Balance: {item.balance}. Wallet: {item.wallet_address.substring(0, 20)}......{item.wallet_address.substring(item.wallet_address.length - 20)}
                      </Dropdown.Item>
                    )
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="card-body">
              <div className="amount-bx">
                <label>To (recipient's wallet address)</label>
                <input
                  type="text"
                  className="form-control grayInput"
                  placeholder="wlRrBWXA3EDd7Ps6zYNObkVnlO.........+vA=="
                  onChange={(e) => setRecipientWalletAddress(e.target.value)}
                  value={recipientWalletAddress}
                />
              </div>

              <div className="amount-bx">
                <label>Amount DEFCUR</label>
                <input
                  type="number"
                  className="form-control grayInput"
                  placeholder="0"
                  onChange={(e) => changeAmountTransfer(e.target.value)}
                  value={amountTransfer}
                />
              </div>

              <div className="amount-bx">
                <label>Reference</label>
                <input
                  type="text"
                  className="form-control grayInput"
                  placeholder="It can be any text"
                  onChange={(e) => setReference(e.target.value)}
                  value={reference}
                />
              </div>

              <button
                className="btn btn-block btn-primary d-block btn-lg text-uppercase"
                onClick={() => setBasicModal(true)}
                disabled={buttonDisabled}
              >
                Send Coins
              </button>
            </div>
          </div>
        </div>
      </div>

      <Modal className="fade" show={basicModal}>
        <Modal.Header>
          <Modal.Title>Confirm Transfer</Modal.Title>
          <Button
            variant=""
            className="close"
            onClick={() => {setBasicModal(false); setNotEnoughBalance(false);}}
          >
            <span>&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>

          <span className="text-break"><b>From: </b> {selectedWallet.wallet_address}</span><br/>
          <span className="text-break"><b>To: </b> {recipientWalletAddress}</span><br/>
          <b>Amount: </b> {amountTransfer}<br/>
          <b>Fee: </b> {fee}<br/>
          <b>Amount to be received: </b> {amountToReceive}<br/><hr/>

          {transferSent === false && (
            <div className="form-group">
              <label className="mb-1 ">
                <strong>Passcode</strong>
              </label>
              <input
                type="password"
                className="form-control"
                placeholder="******"
                onChange={(e) => {setPasscode(e.target.value); setTransferError('')}}
                value={passcode}
              />
            </div>
          )}

          {transferSent === true && (
            <Alert variant="success" className="alert-dismissible fade show">
              The transaction has been successfully confirmed. Once this transaction is approved by the network, you will see it in the list of transactions for your wallet.
            </Alert>
          )}

          {notEnoughBalance === true && (
            <Alert variant="danger" className="alert-dismissible fade show">
              There isn't enough balance to complete this transaction.
            </Alert>
          )}

          {transferError !== '' && (
            <Alert variant="danger" className="alert-dismissible fade show">
              {transferError}
            </Alert>
          )}

          {transferSent === false && (
            <>
              <hr/>
              <Alert variant="warning" className="alert-dismissible fade show">
                <b>IMPORTANT:</b> when your transaction will be confirmed, it will not possible to cancel.
              </Alert>
            </>
          )}

          {transferSent === false && (
            <button className="btn btn-block btn-warning" onClick={() => sendTransferRequest()} disabled={disabledTransferButton}>Confirm</button>
          )}

          <Button
            onClick={() => {setBasicModal(false); setTransferError(''); setNotEnoughBalance(false);}}
            variant="info light"
            className="btn-block"
          >
            Close
          </Button>

        </Modal.Body>
      </Modal>

    </>
  );
};

export default Transfer;
